import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { AbsoluiteImage, GifImage, PageWrapper } from "../../../Styles/style";
import LockerMenu from "../../../Components/LockerMenu/index";
import Marquee from "react-fast-marquee";
import SectionsHeading from "../../SectionsHeading";
const ContinerWraper = styled(Container)`
  /* min-height: 100vh; */
  width: 100%;
  padding: 0 !important;
`;

const GridWraper = styled(Grid)`
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
`;

const BottomText = styled(Typography)`
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "28px")};
  font-family: ${(props) => (props.fm ? props.fm : "Pulang")};
  /* text-shadow: 0px 5px 30px #ff0000; */
  color: #ecc31f;
  position: relative;
  z-index: 1;
  @media (max-width: 899px) {
    margin: 200px 0 0;
    font-size: ${(props) => (props.fs899 ? props.fs899 : "16px")};
  }
`;
const Div = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 111;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #000000e1;
  transition: all 1s ease;
`;
const PopupDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
`;
const InnerDiv = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: center;
  column-gap: 20px;
  width: 100%;
  background-color: ${(props) => (props.bgc ? props.bgc : "#007AC2")};
  color: ${(props) => (props.c ? props.c : "#fff")};
  padding: ${(props) => (props.p ? props.p : "20px 0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "28px")};
  font-family: ${(props) => (props.fm ? props.fm : "Constan")};
  @media (max-width: 899px) {
    font-size: ${(props) => (props.fs899 ? props.fs899 : "16px")};
  }
`;
const Btn = styled(Button)`
  background-color: ${(props) => (props.bgc ? props.bgc : "#007AC2")};
  color: ${(props) => (props.c ? props.c : "#fff")};
  padding: ${(props) => (props.p ? props.p : "10px 30px")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "22px")};
  font-family: ${(props) => (props.fm ? props.fm : "Constan")};
  border-radius: 30px;
  @media (max-width: 899px) {
    font-size: ${(props) => (props.fs899 ? props.fs899 : "16px")};
  }
  :hover {
    background-color: ${(props) => (props.bgc ? props.bgc : "#007AC2")};
  }
`;
const Audio = styled.audio`
  z-index: 99;
  &::-webkit-media-controls-panel {
    background-color: yellow !important;
    border-radius: none;

    // align-self: center;
  }
`;
const HeroSection = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const playAudio = () => {
    setIsPlaying(true);
    setPopupState(false);
  };
  useEffect(() => {
    setPopupState(true);
  }, []);
  return (
    <PageWrapper id="home">
      {popupState ? (
        <Div>
          <PopupDiv>
            <InnerDiv style={{ fontWeight: "bolder" }}>
              Lord Trump $MAGAII
            </InnerDiv>
            <InnerDiv style={{ fontWeight: "bolder" }} bgc="#000">
              WELCOME YOU DEGENERATES
            </InnerDiv>
            <InnerDiv bgc="#000">Do you wish to Make America Great Again?</InnerDiv>
            <InnerDiv bgc="#fff">
              <Btn onClick={playAudio}>Yes</Btn>
              <Btn
                bgc="#FF0000"
                href="https://www.urbandictionary.com/define.php?term=Jeet"
              >
                No
              </Btn>
            </InnerDiv>
          </PopupDiv>
        </Div>
      ) : (
        ""
      )}

      <LockerMenu />
      <ContinerWraper maxWidth="xxl">
        <Container>
          <GridWraper container>
            <AbsoluiteImage
              t="5%"
              l="40%"
              l899="32%"
              tran="translate(-40%,-0%)"
              mw="50%"
              mw899="70%"
              mw599="100%"
              src="./Images/heroImage.jpg"
              alt=""

              style={{ mixBlendMode:"screen" }}
            />
            {/* <AbsoluiteImage t="100%" l="50%" tran="translate(-50%,-80%)" mw="50%" mw899="80%" mw599="100%" src="./Images/herobg.png" alt="" /> */}
            {/* <Grid item xs={12} sm={1}>
            </Grid> */}
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={12} sm={8}>
              <Marquee
                speed="50"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="./Images/trump.gif"
                  style={{ width: "25px" }}
                  alt="gif"
                />
                <SectionsHeading fs="20px" fs899="17px" m="0 10px">
                  SCREW YOU JOE
                </SectionsHeading>

                <img
                  src="./Images/trump.gif"
                  style={{ width: "25px" }}
                  alt="gif"
                />
              </Marquee>
            </Grid>
            <Grid item xs={0} sm={2}></Grid>
            <Grid item xs={12} sm={4}>
              {/* <GifImage
                vis="hidden"
                src="./Images/crying-obama.gif"
                alt="GIF"
              /> */}
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight:"70vh",
              }}
              item
              xs={12}
              sm={4}
            >
              <div></div>
              <BottomText>
                HE IS BACK <br />
                TRUMP IS BACK <br />
                COMING SOON IN 2024
              </BottomText>
              {isPlaying && (
                <Audio autoPlay loop controls style={{ alignSelf: "center" }}>
                  <source src="./music/music.mp3" type="audio/mpeg" />
                  <source src="./music/music.ogg" type="audio/ogg" />
                </Audio>
              )}
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* <GifImage
                m="300px 0 0"
                disp="none"
                src="./Images/PoorObama.gif"
                alt="GIF"
              /> */}
            </Grid>
          </GridWraper>
        </Container>
      </ContinerWraper>
    </PageWrapper>
  );
};

export default HeroSection;
