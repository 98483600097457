import React from "react";
import { DesktopMainMenu,  MenuLink } from "../styles";
import HeroHeading from "../../heroHeading";

const DesktopMenu = (props) => {

  return (
    <DesktopMainMenu maxWidth="xl">
      <HeroHeading>WELCOME TO LORD TRUMP!</HeroHeading>
      <div>
        {props.menuList.map((value, i) => (
          <>
            <MenuLink
              key={i}
              href={value.link}
              target={value.target}
              className={value.customClass}
            >
              {value.title}
            </MenuLink>
            {i !== props.menuList.length - 1 && (
            "☠️"
          )}
          </>
        ))}
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
