import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'

const Heading = styled(Typography)`
    padding: ${props=> props.p ? props.p : "0px"};
    margin: ${props=> props.m ? props.m : "0"};
    text-align: ${props=> props.ta ? props.ta : "left"};
    font-size: ${props=> props.fs ? props.fs : "40px"};
    font-family: ${props=> props.fm ? props.fm : "Pulang"};
    /* text-shadow: 0px 0px 5px #ffffffb3; */
    text-transform: uppercase;
    @media(max-width:899px){
        font-size: ${props=> props.fs899 ? props.fs899 : "32px"};

    }
`

const SectionsHeading = (props) => {
    const {children} = props
  return (
    <Heading color="#ECC31F" {...props}>
        {children}
    </Heading>
  )
}

export default SectionsHeading