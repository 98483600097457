import { Container } from '@mui/material';
import React from 'react'
import DesktopMenu from './component/DesktopMenu';
import MobileMenu from './component/MobileMenu';

const LockerMenu = (props) => {
    const menuList = [ // You can pass 4 perameters for menu title, link, target and customClass
        {
        title: "ABOUT",
        link: "#about",
        },
        {
        title: "DEXTOOLS",
        link: "https://www.dextools.io/app/en/ether/pair-explorer/0x677fea961eee89da8c946e11ea70d93044a73082?t=1719799033685",
        },
        {
        title: "HOW TO BUY",
        link: "https://app.uniswap.org/swap?inputCurrency=eth&outputCurrency=0xaA0181e6565346ad64094198eC854405fF54B1CD&chain=ethereum",
        },
        // {
        // title: "WHITEPAPER",
        // link: "",
        // target:"blank"
        // },
        // {
        // title: "MEMES",
        // link: "#memes",
        // },
        {
        title: "TELEGRAM",
        link: "https://t.me/lordtrumpToken",
        target:"blank"
        },
        {
        title: "TWITTER",
        link: "https://x.com/lordtrumpToken",
        target:"blank"
        },
    ];
  return (
    <Container>
        <DesktopMenu menuList={menuList} />
        <MobileMenu menuList={menuList} />
    </Container>
  )
}

export default LockerMenu