import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'

const Heading = styled(Typography)`
  font-size: ${ (props) => props.fs? props.fs: '17px'};
  font-family: Constan;
  text-align: center;
  color: ${ (props) => props.theme.textSecondary};
  text-transform: uppercase;
  background: ${ (props) => props.theme.textPrimary};;
  padding: ${ (props) => props.p? props.p: '0 5px'};
  margin: ${ (props) => props.m? props.m: '0 0 10px'};
  cursor: pointer;
  transition-duration: 0.5s;
  text-decoration: underline;
  text-decoration-color: #3B5CFF;

  &:hover, &:active, &:focus {
    color: #000;
    transition-duration: 0.5s;
  }
  @media (max-width: 899px) {
    font-size: ${ (props) => props.fs899};
    /* padding: ${ (props) => props.p? props.p: '15px 0 15px 20px'}; */
  }
`

const HeroHeading = (props) => {
    const {children} = props
  return (
    <Heading color="#fff" {...props}>
        {children}
    </Heading>
  )
}

export default HeroHeading