import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import SectionsHeading from "../../SectionsHeading";
import {
  GifImage,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: center;
  align-items: center;
  padding: 100px 0 0;
`;
const ContinerWraper = styled(Container)`
  background: ${(props) => props.theme.bgPrimary};
  height: auto;
  width: 100%;
  padding: 0 !important;
`;

const AboutSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="about">
      <Container>
        <GridWraper container>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <GifImage mw="250px" mw599="180px" src="./Images/Crypto bros.png" />
          </Grid>
          <Grid item xs={12}>
            <SectionsHeading ta="center">About the token</SectionsHeading>
          </Grid>
          <Grid item xs={10} md={8}>
            <Paragraph>
            Welcome to the electrifying, most epic crypto showdown on the blockchain – Lord Trump! Dive headfirst into the political carnival where the infamous Trump vs. Joe Biden saga takes center stage in the crypto universe. <br />

Picture this: a digital battlefield where tweets become weapons, memes are your armor, and the stakes are higher than a presidential debate! Lord Trump isn't just a token; it's a front-row seat to the craziest political spectacle you've ever seen. <br />

Whether you're a die-hard Trump supporter, a Biden enthusiast, or just here for the LOLs, our token is your golden ticket to the ultimate political rollercoaster. <br />

So, buckle up, HODL tight, and let the wild ride begin! The future is here!
            </Paragraph>
          </Grid>
        </GridWraper>
      </Container>
    </ContinerWraper>
  );
};

export default AboutSection;
