import React from "react";
import styled from "@emotion/styled";
import HeroSection from "../../Components/Sections/S1-Hero";
import AboutSection from "../../Components/Sections/S2-About";
import TokenomicsSection from "../../Components/Sections/S3-Tokenomics";
import HowSection from "../../Components/Sections/S4-How to buy";
import MemesSection from "../../Components/Sections/S5-Memes";
const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;

const MainPage = () => {
  return (
    <>
      <div>
        <VideoBK autoPlay loop muted>
          <source src="/Images/background1.mp4" type="video/mp4" />
        </VideoBK>
        <HeroSection />
        <AboutSection />
        <TokenomicsSection />
        <HowSection />
        <MemesSection />
      </div>
    </>
  );
};

export default MainPage;
