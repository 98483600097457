import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import SectionsHeading from "../../SectionsHeading";
import { GifImage } from "../../../Styles/style";
import { MenuLink } from "../../LockerMenu/styles";
import Paragraph from "../../Paragraph";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: center;
  align-items: end;
  padding: 100px 0 10px;
  column-gap: 20px;
`;
const ContinerWraper = styled(Container)`
  background: ${(props) => props.theme.bgPrimary};
  height: auto;
  width: 100%;
  padding: 0 !important;
`;
const GridItem = styled(Grid)`
  margin: 30px 0;
  & .marginDiv {
    background: #070f29;
  }
  :hover {
    & .marginDiv {
      background: linear-gradient(to top, #fff, transparent);
    }
    & .shadow {
      filter: blur(10px);
    }
  }
`;
const MemesSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="memes">
      <Container>
        <GridWraper container>
          {/* <Grid item xs={12}>
            <SectionsHeading ta="center">community Memes</SectionsHeading>
          </Grid>
          <GridItem item xs={12} sm={3}>
            <GifImage m="0" src="./Images/Donaldtrumpnewmugshot.gif" />
          </GridItem>
          <GridItem item xs={12} sm={3}>
            <GifImage m="0"  src="./Images/meme1.png" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0"  src="./Images/meme2.png" />
          </GridItem> */}
           {/* <GridItem item xs={12} sm={3}>
            <GifImage m="0" src={"./Images/meme/1.jpg"} />
          </GridItem>
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/2.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/3.jpg" />
          </GridItem>{" "}
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/meme/4.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/meme/5.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/6.jpg" />
          </GridItem>{" "}
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/7.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/8.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/9.jpg" />
          </GridItem>{" "}
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/10.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/11.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={5}>
            <GifImage m="0" src="./Images/meme/12.jpg" />
          </GridItem>{" "} */}
          {/*
          <GridItem item xs={12} sm={3}>
            <GifImage m="0" src="./Images/meme/10.webp" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/19.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={2}>
            <GifImage m="0" src="./Images/11.jpg" />
          </GridItem>{" "}
          <GridItem item xs={12} sm={3}>
            <GifImage m="0" src="./Images/12.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/20.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={2}>
            <GifImage m="0" src="./Images/13.jpg" />
          </GridItem> */}
          {/* <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/meme10.png" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/m18.png" />
          </GridItem> */}
          {/* <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/15.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={2}>
            <GifImage m="0" src="./Images/14.jpg" />
          </GridItem>
          <GridItem item xs={12} sm={3}>
            <GifImage m="0" src="./Images/m1.png" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/m2.png" />
          </GridItem>
          <GridItem item xs={12} sm={2}>
            <GifImage m="0" src="./Images/m3.png" />
          </GridItem>
          <GridItem item xs={12} sm={3}>
            <GifImage m="0" src="./Images/m4.png" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/m5.jpeg" />
          </GridItem>
          <GridItem item xs={12} sm={2}>
            <GifImage m="0" src="./Images/m6.jpeg" />
          </GridItem>
          <GridItem item xs={12} sm={3}>
            <GifImage m="0" src="./Images/m7.png" />
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/m8.png" />
          </GridItem>
          <GridItem item xs={12} sm={2}>
            <GifImage m="0" src="./Images/m9.gif" />
          </GridItem>
          <GridItem item xs={12} sm={3}>
            <GifImage m="0" src="./Images/m10.png" />
          </GridItem> */}
          {/* <GridItem item xs={12} sm={9.5}>
            <GifImage m="0" src="./Images/meme4.png" />
          </GridItem>
          <GridItem item xs={12} sm={6}>
            <GifImage m="0" src="./Images/dancing.gif" />
          </GridItem> */}
          {/* <GridItem item xs={12}>
            <Paragraph fs="26px" fs599="18px">
              More memes are coming...
            </Paragraph>
          </GridItem> */}
          <GridItem
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            ☠️
            <MenuLink
              m="0"
              target="blank"
              href="https://t.me/lordtrumpToken"
            >
              TELEGRAM
            </MenuLink>
            ☠️
            <MenuLink
              m="0"
              target="blank"
              href="https://x.com/lordtrumpToken"
            >
              TWITTER
            </MenuLink>
            ☠️
            <MenuLink
              m="0"
              target="blank"
              href="https://www.dextools.io/app/en/ether/pair-explorer/0x677fea961eee89da8c946e11ea70d93044a73082?t=1719799033685"
            >
              DEXTOOLS
            </MenuLink>
            ☠️
          </GridItem>
        </GridWraper>
      </Container>
    </ContinerWraper>
  );
};

export default MemesSection;
