import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import SectionsHeading from "../../SectionsHeading";
import {
  GifImage,
} from "../../../Styles/style";
import Paragraph from "../../Paragraph";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: center;
  align-items: center;
  /* padding: 100px 0; */
  column-gap: 50px;
  @media (max-width: 899px) {
    
  }
`;

const ContinerWraper = styled(Container)`
  height: auto;
  width: 100%;
  padding: 0 !important;
`;
const GridItem = styled(Grid)``;
const HowSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="how to buy">
      <Container>
        <GridWraper container>
          <Grid item xs={12}>
            <SectionsHeading ta="center">How to buy</SectionsHeading>
          </Grid>
          <GridItem item xs={12}>
            <Paragraph>
              1. Download the Metamask extension and create an account on
              MetaMask.io <br />
              2. Connect MetaMask to Uniswap and acquire some $MAGAII
            </Paragraph>
          </GridItem>
          <GridItem item xs={12} sm={4}>
            <GifImage m="0" src="./Images/raining-money-donald.gif"/>
          </GridItem>
        </GridWraper>
      </Container>
    </ContinerWraper>
  );
};

export default HowSection;
