import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React from "react";
import SectionsHeading from "../../SectionsHeading";
import { GifImage } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import HeroHeading from "../../heroHeading";

const GridWraper = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  /* flex-direction:column; */
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 100px 0;
  column-gap: 24px;
`;
const ContinerWraper = styled(Container)`
  background: ${(props) => props.theme.bgPrimary};
  height: auto;
  width: 100%;
  padding: 0 !important;
`;
const GridItem = styled(Grid)`
  /* margin: 24px 0; */
  position: relative;
  @media (max-width: 899px) {
    font-size: 16px !important;
  }
`;

const TokenomicsSection = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="tokenomics">
      <Container>
        <GridWraper container>
          <Grid item xs={12}>
            <SectionsHeading ta="center">Tokenomics</SectionsHeading>
          </Grid>
          <GridItem item xs={12}>
            <GifImage m="0" mw="100%" src="./Images/tokenomicsimage.png" />
          </GridItem>
          <GridItem item xs={12}>
            <Paragraph fs="26px" fs599="18px">
              Name: Lord Trump <br /> Symbol: $MAGAII
              <br /> Supply: 47,000,000 <br /> Tax: 0/0
            </Paragraph>
          </GridItem>
          <GridItem
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "24px",
            }}
          >
            ☠️
            <HeroHeading fs="24px" fs899="16px" m="0">
              CA:0xaA0181e6565346ad64094198eC854405fF54B1CD
            </HeroHeading>
            ☠️
          </GridItem>
        </GridWraper>
      </Container>
    </ContinerWraper>
    // 0*326473864gehsgdvageye3
  );
};

export default TokenomicsSection;
